import React, { Component } from "react";
import { ReactFragment } from "react";
import shareImage from '../../Images//share.png';
import quickAddImage from '../../Images/quick-add.png';
import heroImage from '../../Images/hero.png';
import activityImage from '../../Images/activity.png';
import commentsImage from '../../Images/comments.png';
import updatesImage from '../../Images/updates.png';
import './style.sass';
import '../../CoreUI/styles.sass';
import Button, { ButtonSize, ButtonType } from "../../CoreUI/Button/component";

interface Props {}
interface State {
    heroTextIndex: number
}

class HomePage extends Component<Props, State> {
    timeout?: NodeJS.Timeout
    heroTextHighlight = 'life'
    heroTextOptions = ['getting groceries', 'planning vacation', 'paying bills', 'moving in', this.heroTextHighlight, this.heroTextHighlight]

    constructor(props: Props) {
        super(props);
        this.state = {
            heroTextIndex: 0
        }
    }

    componentDidMount() {
        this.timeout = setInterval(() => {
            let currentIndex = this.state.heroTextIndex
            this.setState({
                heroTextIndex: currentIndex + 1
            })
        }, 2500);
    }

    componentDidUnmount() {
        if (this.timeout) {
            clearInterval(this.timeout)
        }
    }

    render() {
        return (
            <div className={'homepage'}>
                {this.getHero()}
                {this.getFeaturesSection()}
                {this.getPricingSection()}
            </div>
        )
    }

    getHero() {
        const text = this.heroTextOptions[this.state.heroTextIndex % this.heroTextOptions.length]

        return (
            <div className={'homepage__section homepage__hero flex-grid'}>
                <div className={'homepage__hero-text col'}>
                    <div>
                        <h1 className={'homepage__headline'}>
                            Make <span className={`homepage__hero-text-change ${text == this.heroTextHighlight ? 'homepage__hero-text-change--highlight': ''}`}>{text}</span> together easy.
                        </h1>
                        <p className={'homepage__caption'}>
                            Cereal is a new way to share plans, goals, and to-dos with close friends and family.
                        </p>
                        <div>
                            <Button
                                title={'Get Cereal'}
                                tapHandler={this.tappedGetCereal}
                                size={ButtonSize.large}
                                type={ButtonType.shadow}
                            />
                        </div>
                        <p className={'homepage__hero-caption'}>
                            Available on the App Store.
                        </p>
                    </div>
                </div>
                <div className={'col'}>
                    <img className={'homepage__hero-image'} src={heroImage} alt="Hero Image" />
                </div>
            </div>
        )
    }

    getFeaturesSection() {
        return (
            <div className={'homepage__section'} id="features">
                {this.getFeatureSection(
                    "Plan",
                    "Create todo's with quickly.",
                    false,
                    <React.Fragment>
                        <img className={'homepage__feature-img'} src={quickAddImage} alt="Quick Add" />
                    </React.Fragment>
                )}
                {this.getFeatureSection(
                    "Share",
                    "Add friends and family easily to items.",
                    true,
                    <React.Fragment>
                        <img className={'homepage__feature-img'} src={shareImage} alt="Hero Image" />
                    </React.Fragment>
                )}
                {this.getFeatureSection(
                    "Track",
                    "Get real-time updates on progress and activity related to what's on your list.",
                    false,
                    <React.Fragment>
                        <img className={'homepage__feature-img'} src={updatesImage} alt="Updates" />
                    </React.Fragment>
                )}
                {this.getFeatureSection(
                    "Discuss",
                    "Use comments to hash out details.",
                    true,
                    <React.Fragment>
                        <img className={'homepage__feature-img'} src={commentsImage} alt="Chat" />
                    </React.Fragment>
                )}
                {this.getFeatureSection(
                    "Celebrate",
                    "Show love for when something done!",
                    false,
                    <React.Fragment>
                        <img className={'homepage__feature-img'} src={activityImage} alt="Likes Activity" />
                    </React.Fragment>
                )}
            </div>
        )
    }

    getFeatureSection(title: string, caption: string, isRightLayout: boolean, img: ReactFragment) {
        if (isRightLayout) {
            return (
                <div className={'homepage__feature flex-grid'}>
                    <div className={'homepage__feature-description col'}>
                        <h1 className={'homepage__title'}>
                            {title}
                        </h1>
                        <p>
                            {caption}
                        </p>
                    </div>
                    <div className={'homepage__feature-img-column col'}>
                        {this.getCroppedPicture(img)}
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'homepage__feature flex-grid'}>
                    <div className={'homepage__feature-img-column col'}>
                        {this.getCroppedPicture(img)}
                    </div>
                    <div className={'homepage__feature-description col'}>
                        <h1 className={'homepage__title'}>
                            {title}
                        </h1>
                        <p>
                            {caption}
                        </p>
                    </div>
                </div>
            )
        }
    }

    getCroppedPicture(img: ReactFragment) {
        return (
            <div className={'homepage__feature-img-container'}>
                {img}
            </div>
        )
    }

    getPricingSection() {
        return (
            <div className={'homepage__section homepage__pricing flex-grid'} id="pricing">
                <div className={'col homepage__pricing-body'}>
                    <h1 className={'homepage__title'}>
                        Pricing
                    </h1>
                    <p>
                        Cereal is <b>free</b> to use, with no time limits.
                    </p>
                    <p>
                        You can boost your productivity with <b>All Access</b> and subscribe to premium features like unlimited items, lists, reminders, and due dates. All Access also unlocks all future premium features.
                    </p>
                    <p>
                        <b>Cereal All Access</b> subscription has two options:
                    </p>
                    <ul>
                        <li>
                            <p><b>$3.99 monthly</b></p>
                        </li>
                        <li>
                            <p><b>$23.99 annually</b>, with <b>1 week free trial</b></p>
                        </li>
                    </ul>
                    <p>
                        Prices may vary based on your region's local tax laws and conversion rates from U.S. dollars.
                    </p>
                </div>
            </div>
        )
    }

    // link to app store
    tappedGetCereal() {
        window.open('https://apps.apple.com/us/app/cereal-todos-with-friends/id1538250733', '_blank')
    }
}

export default HomePage;
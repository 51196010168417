import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import './style.sass';

interface Props {}
interface State {}

class PrivacyPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return(
            <div className={'privacy'}>
                <Helmet>
                    <title>Cereal Privacy Policy</title>
                </Helmet>
                <h2 className={'privacy__header'}>
                    Privacy Policy
                </h2>

                <div className={'privacy__section'}>
                    <p>
                        This policy describes the data we, Pamango LLC, collect and use to support Cereal, including the mobile application and related services. By using our services, you agree to the data practices described by this privacy policy.
                    </p>
                </div>

                {this.getDataWeCollectSection()}
                {this.getHowWeUseInformationSection()}
                {this.getHowWeShareInformation()}
                {this.getHowLongWeKeepInformation()}
                {this.getControlOverYourPersonalDataSection()}
                {this.getEuropeanUnionSection()}
                {this.getCaliforniaSection()}
                {this.getChildrenSection()}
                {this.getPrivacyChangesSection()}
                {this.getVersionSection()}
            </div>
        )
    }

    // links
    getTermsOfUseLink() {
        return (
            <Link to={'/terms'}>Terms of Use</Link>
        )
    }

    getSupportEmailLink() {
        return (
            <a target="_blank" href={`mailto:support@pamango.com`}>support@pamango.com</a>
        )
    }

    getDataWeCollectSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>Data We Collect</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        To provide you with our products and services, we collect and process the following types of data:
                    </p>
                    <ul>
                        <li>
                            <b>Information and content you provide:</b> We collect the content, communications, and other information you provide when you sign up for an account, create or share content, and communicate with others. To create and manage an account, you may provide us with personal data, including a username, a photo of yourself, and an email address.
                        </li>
                        <li>
                            <b>Your networks and connections:</b> We collect information about the people and accounts you are connected to and how you interact with them through Cereal and its related services.
                        </li>
                        <li>
                            <b>Your usage:</b> We collect information about how you use our products, including the content you share or interact with, features you use, the actions you take, and people or accounts you interact with. We also log the time, frequency, and duration of your use.
                        </li>
                        <li>
                            <b>Device information:</b> We collect information from and about the devices you use. For example, we collect:
                            <ul>
                                <li>
                                    - Device attributes: information about the operating system, hardware, and software versions.
                                </li>
                                <li>
                                    - Device operations: information about operations and behaviors performed on the device, such as whether a window is foregrounded or backgrounded.
                                </li>
                                <li>
                                    - Data from device settings: information you allow us to receive through device settings you turn on, such as access to camera or photos.
                                </li>
                                <li>
                                    - Identifiers: including unique identifiers and device IDs.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Cookies and other similar technologies:</b> We use cookies and other similar technologies, such as web beacons, tags, and scripts, to collect information about your activity and preferences. You can usually choose to remove or reject browser cookies through your browser or device settings. If you limit the website’s ability to set cookies, you may be unable to access certain features or services.
                        </li>
                        <li>
                            <b>Communications data:</b> We may choose to collect information when you contact us with questions or feedback and when you voluntarily respond to requests for your opinion.
                        </li>
                    </ul>
                </div>
        </div>
        )
    }

    getHowWeUseInformationSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>How We Use Information</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        We use the data we collect to provide and support Pamango products and services in the following ways:
                    </p>
                    <ul>
                        <li>
                            - develop, operate, maintain, deliver, and improve our products and services.
                        </li>
                        <li>
                            - personalize your experience with our products and services.
                        </li>
                        <li>
                            - send communications, including by email. This may include marketing or responses to support inquiries.
                        </li>
                        <li>
                            - monitor and analyze trends and usage.
                        </li>
                        <li>
                            - enhance the safety and security of our products and services.
                        </li>
                        <li>
                            - authenticate your account and keep it secure.
                        </li>
                        <li>
                            - enforce our {this.getTermsOfUseLink()}, other usage policies, and comply with legal requirements.
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    getHowWeShareInformation() {
        return(
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>How We Share Information</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        We're committed to your privacy and do not sell your data in any way. We may share information about you in the following ways:
                    </p>
                    <ul>
                        <li>
                            <b>With other users:</b> Certain actions you take or content you share may be visible to other users of our products and services. For example, when you upload profile photos or update your username, other users will be able to access this content and information. We also share when you are currently active on our products with people within your network.
                        </li>
                        <li>
                            <b>Vendors and service providers:</b> We use third-party vendors and service providers to support our business. We use them for purposes such as technical infrastructure services, analytics, and customer service.
                        </li>
                        <li>
                            <b>Legal enforcement or requests:</b> We may share information about you under the following circumstances:
                            <ul>
                                <li>
                                    - to comply with legal requirements, including to meet national security or law enforcement requirements
                                </li>
                                <li>
                                    - to investigate or enforce {this.getTermsOfUseLink()} violations.
                                </li>
                                <li>
                                    - to protect and defend the rights, property, or safety of us, our users, or the public. 
                                </li>
                                <li>
                                    - to detect and resolve any fraud or security concerns.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    getHowLongWeKeepInformation() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>How Long We Keep Information</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        We keep data we've collected for as long as reasonably necessary for the purposes described in this Privacy Policy, while we have a business to do so, or as required by law, such as for tax, legal, accounting, or other purposes, whichever is longer.
                    </p>
                </div>
            </div>
        )
    }

    getControlOverYourPersonalDataSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>Control Over Your Personal Data</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        You can access and edit your account information, including username and profile picture, at any time in the Cereal mobile app. If you decide to stop using our products and services, you can delete your account yourself under Account Settings in the app or by sending us a request at {this.getSupportEmailLink()}.
                    </p>
                </div>
            </div>
        )
    }

    getEuropeanUnionSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>Users in the European Union</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        If you’re a resident of the European Union and European Economic Area, you have certain data protection rights covered by the General Data Protection Regulation (GDPR).
                    </p>
                    <p>
                        We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal data.
                    </p>
                    <p>
                        In certain circumstances, you have the following data protection rights:
                    </p>
                    <ul>
                        <li>
                            - to access, update, or delete your personal data.
                        </li>
                        <li>
                            - to rectify information related to you that is inaccurate or incomplete.
                        </li>
                        <li>
                            - to object to our processing of your personal data.
                        </li>
                        <li>
                            - to request that we restrict the processing of your personal data.
                        </li>
                        <li>
                            - to be provided with a copy of your personal data.
                        </li>
                        <li>
                            - to withdraw consent at any time where we rely on your consent to process your personal data.
                        </li>
                    </ul>
                    <p>
                        To exercise any or all of these rights, you can contact us at {this.getSupportEmailLink()}. Once we receive your request, we will verify your identity by sending an email to the email address you provide to us.
                    </p>
                    <p>
                        You have the right to complain to a Data Protection Authority about our collection and use of your personal data. For more information, please contact your local data protection authority in the European Economic Area.
                    </p>
                </div>
            </div>
        )
    }

    getCaliforniaSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>Users in California</h3>
                <div className={'privacy__section-body'}>
                    <p>
                        If you're a California resident, you have certain privacy rights under California law, including the California Consumer Privacy Act of 2018 ("CCPA"). These rights include the ability to make certain requests and ask us:
                    </p>
                    <ul>
                        <li>
                            - what personal information we have about you.
                        </li>
                        <li>
                            - to delete your personal information.
                        </li>
                    </ul>
                    <p>
                        To exercise any or all of these rights, you can contact us at {this.getSupportEmailLink()}. Once we receive your reqest, we will verify your identitiy by sending an email to the email address you provide to us.
                    </p>
                    <p>
                        Per the California Privacy Protection Act (CalOPPA), we also agree to the following:
                    </p>
                    <ul>
                        <li>
                            - you can visit our website anonymously.
                        </li>
                        <li>
                            - our Privacy Policy link includes the word "Privacy" and can be easily found on our website.
                        </li>
                        <li>
                            - you will be notified of any privacy policy changes.
                        </li>
                        <li>
                            - you can change your personal information through the Cereal mobile application or by contacting us at {this.getSupportEmailLink()}.
                        </li>
                    </ul>
                    <p>
                        We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.
                    </p>
                    <p>
                        You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
                    </p>
                </div>
            </div>
        )
    }

    getChildrenSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>Children</h3>
                <div className={'privacy__section-body'}>
                    Our products and services are not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you become aware that a child has provided us with personal data, please contact us at {this.getSupportEmailLink()}. If we become aware that we have collected personal data from a child without verification of parental consent, we will take steps to remove that information from our servers.
                </div>
            </div>
        )
    }

    getPrivacyChangesSection() {
        return (
            <div className={'privacy__section'}>
                <h3 className={'privacy__section-header'}>Changes to the Privacy Policy</h3>
                <div className={'privacy__section-body'}>
                    We may change our Privacy Policy from time to time. When we do, we will revice the last updated date found at the bottom of the Privacy Policy that is available on the Cereal website and mobile application. We may also send an email or provide additional notice within the mobile application.
                </div>
            </div>
        )
    }

    getVersionSection() {
        return (
            <div className={'privacy__section'}>
                <p><b>Last Updated:</b> February 3, 2021</p>
            </div>
        )
    }
}

export default PrivacyPage;
import { Switch, Route } from 'react-router';
import HomePage from '../../Pages/Home/component';
import PrivacyPage from '../../Pages/Privacy/component';
import TermsPage from '../../Pages/Terms/component';

export default (
    <Switch>
        <Route exact path="/" component={() => <HomePage />} />
        <Route path="/privacy" component={() => <PrivacyPage />} />
        <Route path="/terms" component={() => <TermsPage />} />
    </Switch>
);
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './Components/NavBar/component';
import Footer from './Components/Footer/component';
import Routes from './Components/Routes/routes';
import { Helmet } from "react-helmet";
import './app.sass';

export default function App() {
  return (
    <div className="app-container">
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Cereal: To-dos with friends</title>
            <meta name="description" content="Cereal is a new, easy way to share plans, goals, and to-dos with others. Available now on the App Store."/>
        </Helmet>
        <Router>
            <NavBar />
            <div className="app-container__body">
                { Routes }
            </div>
            <Footer />
        </Router>
    </div>
  );
}

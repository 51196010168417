import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import SocialBar from "../SocialBar/component";
import './style.sass';
import '../../CoreUI/styles.sass';

interface Props {}
interface State {}

class Footer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <footer className="footer">
                <div className="footer__top flex-grid">
                    <div className="footer__col col-1">
                        <div className="footer__logo">
                            <h1>CEREAL</h1>
                        </div>
                        <div className="footer__caption">
                            <p>Make sharing to-dos easier than ever.</p>
                        </div>
                        <SocialBar />
                    </div>
                    <div className="footer__col">
                        <p className="footer__header">Resources</p>
                        <HashLink className="footer__link" smooth to="/terms#">Terms of Use</HashLink>
                        <HashLink className="footer__link" to="/privacy#">Privacy</HashLink>
                    </div>
                </div>
                <div className="footer__bottom">
                    <p>Made with ♥ by Pamango LLC © </p>
                </div>
            </footer>
        )
    }
}

export default Footer;
import React, { Component } from "react";
import { ReactFragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import './style.sass';

interface Props {}
interface State {}

class TermsPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return(
            <div className={'terms'}>
                <Helmet>
                    <title>Cereal Terms of Use</title>
                </Helmet>
                <h2 className={'terms__header'}>
                    Terms of Use
                </h2>
                {this.getIntroSection()}
                {this.getServicesDescriptionSection()}
                {this.getWhoCanUseCerealSection()}
                {this.getAccountsAndRegistration()}
                {this.getConditionsOfUse()}
                {this.getYourPrivacySection()}
                {this.getRightsWeRetain()}
                {this.getPaymentsAndSubscriptions()}
                {this.getIntellectualPropertySection()}
                {this.getCopyrightComplaintsSection()}
                {this.getDMCASection()}
                {this.getDisclaimerOfWarrantiesSection()}
                {this.getLimitationOfLiabilitySection()}
                {this.getCaliforniaUsersSection()}
                {this.getIOSUsersSection()}
                {this.getChangesToTermsSection()}
                {this.getVersionSection()}
            </div>
        )
    }

    // shared
    getPrivacyPolicyLink() {
        return (
            <Link to={'/privacy'}>Privacy Policy</Link>
        )
    }

    getSupportEmailLink() {
        return (
            <a target="_blank" href={`mailto:support@pamango.com`}>support@pamango.com</a>
        )
    }

    getDMCAEmailLink() {
        return (
            <a target="_blank" href={`mailto:dmca@pamango.com`}>dmca@pamango.com</a>
        )
    }

    getSection(body: ReactFragment, title?: string) {
        return (
            <div className={'terms__section'}>
                { title ? 
                    <h3 className={'terms__header'}>{title}</h3> :
                    undefined
                }
                <div className={'terms__section-body'}>
                    {body}
                </div>
            </div>
        )
    }

    // specific sections
    getIntroSection() {
        const body =
            <React.Fragment>
                <p>
                    These Terms of Use govern your use of Cereal, including the mobile application and related services (collectively referred to as "Service(s)"), and provide information about the product and services, as outlined below. When you create a Cereal account or use Cereal, you agree to these Terms.
                </p>
                <p>
                    Cereal is a product provided to you by Pamango LLC. These Terms of Use therefore constitute an agreement between you and Pamango LLC. If you do not agree to these terms, please do not use the Services.
                </p>
                <p>
                    ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND PAMANGO LLC WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS UNDER THIS CONTRACT (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT). YOUR RIGHTS WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY.
                </p>
            </React.Fragment>
        return this.getSection(body)
    }

    getServicesDescriptionSection() {
        const body =
            <React.Fragment>
                <p>
                    Cereal is designed to allow users to manage plans, goals, and to-dos with friends and other people.
                </p>
            </React.Fragment>
        return this.getSection(body, "Services Description")
    }

    getWhoCanUseCerealSection() {
        const body = 
            <React.Fragment>
                <p>
                    The following are restrictions on who can use Cereal:
                </p>
                <ul>
                    <li>
                        - You are at least 13 years old.
                    </li>
                    <li>
                        - You are not a person who is barred from receiving the Services under the laws of the United States or any applicable jurisdiction.
                    </li>
                    <li>
                        - You are able to form a binding contract with Pamango LLC.
                    </li>
                    <li>
                        - You are not a convicted sex offender.
                    </li>
                    <li>
                        - You will comply with these Terms and all applicable local, state, national, and international laws, rules, and regulations.
                    </li>
                </ul>
            </React.Fragment>
        
        return this.getSection(body, "Who Can Use Cereal")
    }

    getAccountsAndRegistration() {
        const title = "Accounts and Registration"
        const body = 
            <React.Fragment>
                <p>
                    To access Cereal and its services, you may have to register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your email address or name. You agree that the information you provide us is accurate, complete, and current at all times.
                </p>
                <p>
                    You are responsible for maintaining the confidentiality of your account and/or password, including but not limited to the restriction of access to your device and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password. If you believe your account is no longer secure, you must notify us immediately at {this.getSupportEmailLink()}.
                </p>
                <p>
                    You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
                </p>
            </React.Fragment>

        return this.getSection(body, title)
    }

    getConditionsOfUse() {
        const title ="Conditions of Use"
        const body =
            <React.Fragment>
                <p>
                To provide a safe and enjoyable experience for everyone on Cereal, you cannot use the Services in the following ways:
                </p>
                <ul>
                    <li>
                        - <b>You cannot impersonate others or provide inaccurate information.</b> You must provide accurate and up-to-date information, including account information. You can not impersonate someone you are not, and you cannot create an account for someone else unless you have their express permission.
                    </li>
                    <li>
                        - <b>You cannot violate any applicable national or international laws or regulations.</b>
                    </li>
                    <li>
                        - <b>
                        You cannot infringe upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                        </b>
                    </li>
                    <li>
                        - <b>
                            You cannot attempt to create accounts or access or collect information in unauthorized ways.
                        </b>
                    </li>
                    <li>
                        - <b>
                            You cannot bully, harass, intimidate, spam, or solicit other users.
                        </b>
                    </li>
                </ul>
                <p>
                    Any software we provide you may automatically download and install upgrades, updates, or other new features. You can adjust these automatic downloads through your device settings.
                </p>
                <p>
                    <b>Content:</b> Our Service allows you to post, link, store, share, and otherwise make available certain information, such as text or graphics. You alone are responsible for the content you create, upload, send, or store through the Service.
                </p>
                <p>
                    By posting Content on or through Service, you represent and warrant that:
                </p>
                <ul>
                    <li>
                        - the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms.
                    </li>
                    <li>
                        - the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity.
                    </li>
                </ul>
                <p>
                    We reserve the right to terminate the account of anyone found to be infringing on a copyright.
                </p>
                <p>
                    You retain any and all of your rights to any Content you submit, post, or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.
                </p>
                <p>
                    In addition, Content found on or through this Service are the property of Pamango LLC or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
                </p>
            </React.Fragment>

        return this.getSection(body, title)
    }

    getYourPrivacySection() {
        const title = "Your Privacy"
        const body =
            <React.Fragment>
                <p>
                    Providing Cereal and its related services require collecting and using your information. The {this.getPrivacyPolicyLink()} explains how we collect and use information from Cereal and its services. By using the Services, you consent to our collection and use of personal data as outlined within the Privacy Policy. 
                </p>
            </React.Fragment>

        return this.getSection(body, title)
    }

    getRightsWeRetain() {
        const title = "Rights We Retain"
        const body =
            <React.Fragment>
                <p>
                    We have the right but not the obligation to monitor and edit all Content provided by users.
                </p>
                <p>
                    We may terminate or suspend your account and suspend access to Cereal immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.
                </p>
                <p>
                    We may withdraw or amend our Service or products at our sole discretion without notice. We are not liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some or all of the Service to users, including registered users.
                </p>
                <p>
                    If you provide feedback or suggestions, we can use them without compensating you, and without any restriction or obligation to you.
                </p>
            </React.Fragment>
        
        return this.getSection(body, title)
    }

    getPaymentsAndSubscriptions() {
        const title = "Payments and Subscriptions"
        const body =
            <React.Fragment>
                <p>
                    Certain features of the service may require you to pay fees. Before you pay any fees, you will have the opportunity to review and accept the fees that you will be charged.
                </p>
                <ul>
                    <li>
                        <b>Pricing:</b> We reserve the right to determine pricing for Cereal and its services. We will make reasonable efforts to keep pricing information published and up-to-date on our website. If we change the price of any features of the Services, we will provide you with advanced notice. Pamango LLC may also, at its sole discretion, make promotional offers with different features and different pricing for Cereal users. These promotional offers, unless made to you, will not apply to your offer or these Terms.
                    </li>
                    <li>
                        <b>Authorization:</b> We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our {this.getPrivacyPolicyLink()}.
                    </li>
                    <li>
                        <p>
                            <b>Subscriptions:</b> Some parts of Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
                        </p>
                        <p>
                            At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Pamango LLC cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting us at {this.getSupportEmailLink()}.
                        </p>
                        <p>
                            A valid payment method, including credit card, is required to process the payment for your subscription. You shall provide Pamango LLC with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Pamango LLC to charge all Subscription fees incurred through your account to any such payment instruments.
                        </p>
                        <p>
                            Should automatic billing fail to occur for any reason, Pamango LLC will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
                        </p>
                    </li>
                </ul>
            </React.Fragment>
        
        return this.getSection(body, title)
    }

    getIntellectualPropertySection() {
        const title = "Intellectual Property"
        const body =
            <React.Fragment>
                <p>
                    The Services and its original content (excluding Content provided by our users), features, and functionality are and will remain the exclusive property of Pamango LLC and its licensors.
                </p>
                <p>
                    You may not copy, scrape, modify, distribute, sell, lease, or create derivative works of any part of the Services, nor may you reverse engineer or attempt to extract the source code of the software, unless laws prohibit these restrictions or you have our written permission to do so.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getCopyrightComplaintsSection() {
        const title = "Copyright Complaints"
        const body =
            <React.Fragment>
                <p>
                    We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.
                </p>
                <p>
                    If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to dmca@pamango.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”.
                </p>
                <p>
                    You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getDMCASection() {
        const title = "DMCA"
        const body =
            <React.Fragment>
                <p>
                    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
                </p>
                <ul>
                    <li>
                        - an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.
                    </li>
                    <li>
                        - a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.
                    </li>
                    <li>
                        - identification of the URL or other specific location on Service where the material that you claim is infringing is located.
                    </li>
                    <li>
                        - your address, telephone number, and email address.
                    </li>
                    <li>
                        - a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                    </li>
                    <li>
                        - a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
                    </li>
                </ul>
                <p>
                    You can contact our Copyright Agent via email at {this.getDMCAEmailLink()}.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getDisclaimerOfWarrantiesSection() {
        const title = "Disclaimer of Warranties"
        const body =
            <React.Fragment>
                <p>
                THE SERVICE IS PROVIDED BY PAMANGO LLC ON AN “AS IS” AND “AS AVAILABLE” BASIS. PAMANGO LLC MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
                </p>
                <p>
                NEITHER PAMANGO LLC NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                </p>
                <p>
                PAMANGO LLC HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getLimitationOfLiabilitySection() {
        const title = "Limitation of Liability"
        const body =
            <React.Fragment>
                <p>
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getCaliforniaUsersSection() {
        const title = "Users in California"
        const body =
            <React.Fragment>
                <p>
                Under California Civil Code Section 1789.3, users of the Service from California are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210. You can contact us at {this.getSupportEmailLink()}.
                </p>
            </React.Fragment>
        
        return this.getSection(body, title)
    }

    getIOSUsersSection() {
        const title = "Notice for iOS Users"
        const body =
            <React.Fragment>
                <p>
                If you are using our mobile application on an iOS device, you acknowledge that these Terms are between you and Pamango LLC only, not with Apple Inc. (“Apple”), and Apple is not responsible for the Service or the content thereof. Apple has no obligation to furnish any maintenance and support services with respect to the Service. If the Service fails to conform to any applicable warranty, you may notify Apple and Apple will refund any applicable purchase price for the mobile application to you; and, to the maximum extent permitted by applicable law, Apple has no other warranty obligation with respect to the Service. Apple is not responsible for addressing any claims by you or any third party relating to the Service or your possession and/or use of the Service, including: (a) product liability claims; (b) any claim that the Service fails to conform to any applicable legal or regulatory requirement; or (c) claims arising under consumer protection or similar legislation. Apple is not responsible for the investigation, defense, settlement and discharge of any third party claim that the Service and/or your possession and use of the Service infringe a third party’s intellectual property rights. You agree to comply with any applicable third party terms when using the Service. Apple and Apple’s subsidiaries are third party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary of these Terms. You hereby represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getChangesToTermsSection() {
        const title = "Changes to the Terms of Use"
        const body =
            <React.Fragment>
                <p>
                We reserve the right to update our Terms of Use at any time. When we do, we will post the updated terms to this site and indicate when it was last updated. It is your responsibility to review these Terms periodically. Your continued use of Cereal and its services means that you accept and agree to any changes.
                </p>
            </React.Fragment>
        return this.getSection(body, title)
    }

    getVersionSection() {
        const body =
            <React.Fragment>
                <p>
                    <b>Last Updated:</b> February 3, 2021
                </p>
            </React.Fragment>
        return this.getSection(body)
    }
}

export default TermsPage;
import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram, faDiscord } from "@fortawesome/free-brands-svg-icons";

import './style.sass';

interface Props {}
interface State {}

class SocialBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="social-bar">
                <a className="social-bar__item" href="https://www.twitter.com/cerealtodo">
                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a className="social-bar__item" href="https://www.instagram.com/cerealtodo">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a className="social-bar__item" href="https://discord.gg/z5XpS6pb9C">
                    <FontAwesomeIcon icon={faDiscord} size="2x" />
                </a>
            </div>
        )
    }
}

export default SocialBar;
import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import { HamburgerSpring } from "react-animated-burgers"
import './style.sass';
import Button, { ButtonSize, ButtonType } from "../../CoreUI/Button/component";

interface Props {}
interface State {
    isNavMenuOpen: boolean;
    isScrolling: boolean;
}

class NavBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isNavMenuOpen: false,
            isScrolling: false
        }

        this.toggleSlidingMenu = this.toggleSlidingMenu.bind(this);
        this.tappedGetCereal = this.tappedGetCereal.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isScrolling = window.scrollY > 200;
            if (isScrolling != this.state.isScrolling) {
                this.setState({
                    isScrolling: isScrolling
                })
            }
        })
    }

    render() {
        return (
            <div>
                <nav className={`navbar ${this.state.isScrolling ? 'navbar--scrolling' : ''}`}>
                    <HamburgerSpring 
                        className={'navbar__hamburger'}
                        isActive={this.state.isNavMenuOpen}
                        toggleButton={this.toggleSlidingMenu}
                        buttonWidth={30}
                    />
                    <div className={'navbar__logo'}>
                        <HashLink smooth to="/#"><h1>CEREAL</h1></HashLink>
                    </div>
                    <ul className={'navbar__links'}>
                        <li>
                            <HashLink smooth={true} to="/#features">Features</HashLink>
                        </li>
                        <li>
                            <HashLink smooth={true} to="/#pricing">Pricing</HashLink>
                        </li>
                        <li className={'navbar__button'}>
                            {this.getCerealButton(ButtonSize.medium)}
                        </li>
                    </ul>
                </nav>
                {this.getSlidingMenu()}
            </div>
        )
    }

    toggleSlidingMenu() {
        this.setState({
            isNavMenuOpen: !this.state.isNavMenuOpen
        })
    }

    getSlidingMenu() {
        return (
            <div className={`navbar__menu ${(this.state.isNavMenuOpen ? 'navbar__menu--show' : 'navbar__menu--hide')}`}>
                <ul className={'navbar__menu-links'}>
                    <li>
                        <HashLink smooth={true} to="/#features">Features</HashLink>
                    </li>
                    <li>
                        <HashLink smooth={true} to="/#pricing">Pricing</HashLink>
                    </li>
                    <li>
                        {this.getCerealButton(ButtonSize.large)}
                    </li>
                </ul>
            </div>
        )
    }

    getCerealButton(size: ButtonSize) {
        return (
            <Button
                title={'Get Cereal'}
                tapHandler={this.tappedGetCereal}
                size={size}
                type={ButtonType.outline}
            />
        )
    }

    // TODO: REPLACE
    tappedGetCereal() {
        window.open('https://apps.apple.com/us/app/cereal-todos-with-friends/id1538250733', '_blank')
    }
}

export default NavBar;
import React, { Component } from "react";
import './style.sass';

export enum ButtonSize {
    small = "small",
    medium = "medium",
    large = "large"
}

export enum ButtonType {
    text = "text",
    outline = "outline",
    shadow = "shadow"
}

interface Props {
    title: string;
    tapHandler: () => void
    size: ButtonSize
    type: ButtonType
}

interface State {

}

class Button extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <button
                className={`button button--${this.props.type} button--${this.props.size}`}
                title={this.props.title}
                onClick={this.props.tapHandler}
            >
                {this.props.title}
            </button>
        )
    }
}

export default Button;